	/*
  	Flaticon icon font: Flaticon
  	Creation date: 24/03/2020 06:28
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
font-style: normal;

}

.flaticon-problem:before { content: "\f100"; }
.flaticon-list:before { content: "\f101"; }
.flaticon-travel:before { content: "\f102"; }
.flaticon-respect:before { content: "\f103"; }
.flaticon-work:before { content: "\f104"; }
.flaticon-bed:before { content: "\f105"; }
.flaticon-drink:before { content: "\f106"; }
.flaticon-home:before { content: "\f107"; }
.flaticon-food:before { content: "\f108"; }